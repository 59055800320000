<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #content>
          <DataTable
            :value="reservas_cartao"
            class="p-datatable-sm"
            :paginator="true"
            :rows="5"
            stripedRows
            :loading="loading"
            dataKey="id"
            :filters.sync="filtros"
            filterDisplay="menu"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="rowsPerPageOptions"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} reservas de cartão"
            responsiveLayout="scroll">
            <template #empty> Nenhuma reserva de cartão encontrada. </template>
            <template #loading> Carregando. Por favor aguarde. </template>
            <Column header="Cadastrado Em">
              <template #body="{ data }">
                {{ data.auditMetadata.cadastradoEm | formatarData }}
              </template>
            </Column>
            <Column header="Cadastrado Por">
              <template #body="{ data }">
                {{ data.cadastradoPor ? data.cadastradoPor : ' - ' }}
              </template>
            </Column>
            <Column header="Cancelado Em">
              <template #body="{ data }">
                {{ data.dataCancelamento | formatarData }}
              </template>
            </Column>
            <Column header="Cancelado Por">
              <template #body="{ data }">
                {{ data.canceladoPor ? data.canceladoPor : ' - ' }}
              </template>
            </Column>
            <Column
              field="consignataria.nomeFantasia"
              header="Consignatária"></Column>
            <Column field="valor" header="Valor">
              <template #body="{ data }">
                <div class="flex justify-content-end flex-wrap card-container">
                  <div class="flex align-items-center justify-content-center">
                    {{ data.valor | formatarValor }}
                  </div>
                </div>
              </template>
            </Column>
            <Column header="Status">
              <template #body="slotProps">
                <Chip
                  :label="labelChip(slotProps.data.codigoStatusReservaCartao)"
                  :class="
                    classChip(slotProps.data.codigoStatusReservaCartao)
                  " />
              </template>
            </Column>
            <Column header="Ações">
              <template #body="{ data }">
                <Button
                  v-if="exibirBotaoAtivar(data.codigoStatusReservaCartao)"
                  v-tooltip.left="'Ativar'"
                  icon="pi pi-check"
                  class="mt-2 mr-2 p-button-rounded p-button-success"
                  @click="confirmarAcao(data.id, 'ativar')" />
                <Button
                  v-if="
                    exibirBotaoSuspenderOuCancelar(
                      data.codigoStatusReservaCartao,
                    )
                  "
                  v-tooltip.left="'Supender'"
                  icon="pi pi-lock"
                  class="mt-2 mr-2 p-button-rounded p-button-warning"
                  @click="confirmarAcao(data.id, 'suspender')" />
                <Button
                  v-if="
                    exibirBotaoSuspenderOuCancelar(
                      data.codigoStatusReservaCartao,
                    )
                  "
                  v-tooltip.left="'Cancelar'"
                  icon="pi pi-times"
                  class="mt-2 mr-2 p-button-rounded p-button-danger"
                  @click="confirmarAcao(data.id, 'cancelar')" />
                <Button
                  v-tooltip.left="'Histórico'"
                  icon="pi pi-list"
                  class="mt-2 mr-2 p-button-rounded p-button"
                  @click="exibirHistorico(data.historico)" />
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
      <modal-senha-servidor
        :exibir="exibirModalSenhaUsuarioLogado"
        :metodoSalvar="realizarAcao"
        :tipo="false"
        @mudarVisibilidade="exibirModalSenhaUsuarioLogado = $event">
      </modal-senha-servidor>
      <Dialog
        :visible.sync="modalHistorico"
        :containerStyle="{ width: '50vw' }">
        <DataTable
          class="p-datatable-sm"
          :paginator="true"
          :rows="5"
          stripedRows
          :loading="loading"
          :value="historico"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="rowsPerPageOptions"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignações"
          responsiveLayout="scroll">
          <template #header>Histórico</template>
          <template #empty> Nenhum histórico encontrado. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <Column header="Alterado Em">
            <template #body="{ data }">
              {{ data.alteradoEm | formatarData }}
            </template>
          </Column>
          <Column header="Alterado Por">
            <template #body="{ data }">
              {{ data.nomeAlteradoPor }}
            </template>
          </Column>
          <Column field="descricaoStatus" header="Status" />
        </DataTable>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import ReservaCartaoService from '@/service/ReservaCartaoService.js'
import ModalSenhaServidor from '@/components/shared/modal/modalSenhaServidor.vue'

export default {
  components: {
    'modal-senha-servidor': ModalSenhaServidor,
  },
  data() {
    return {
      reservas_cartao: [],
      reservaCartao: {},
      loading: false,
      totalRecords: 0,
      filtros: {},
      exibirModalSenhaUsuarioLogado: false,
      idReservarCartao: null,
      acao: null,
      modalHistorico: false,
      historico: [],
    }
  },

  computed: {
    rowsPerPageOptions() {
      return [5, 10, 25]
    },
  },

  created() {
    this.reservaCartaoService = new ReservaCartaoService(this.$http)
  },

  mounted() {
    this.carregarListaReservarCartao()
  },

  methods: {
    carregarListaReservarCartao() {
      this.loading = true
      this.reservaCartaoService
        .getListaReservaCartaoPorMatricula(this.$route.params.id)
        .then((res) => {
          this.reservas_cartao = res
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    initFiltros() {
      this.filtros = {
        'statusConsignacao.nome': {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
      }
    },
    limparFiltros() {
      this.initFiltros()
    },

    formatarData(value) {
      if (!value) return ''
      return new Date(value).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    },

    formatarValor(value) {
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },

    exibirBotaoAtivar(codigo) {
      return this.$auth.hasRoleGestao() && codigo === 2 ? true : false
    },

    exibirBotaoSuspenderOuCancelar(codigo) {
      return this.$auth.hasRoleGestao() && codigo === 1 ? true : false
    },

    confirmarAcao(id, acao) {
      this.idReservarCartao = id
      this.acao = acao
      this.exibirModalSenhaUsuarioLogado = true
    },

    realizarAcao() {
      this.loading = true
      if ('ativar' === this.acao) {
        this.ativar()
      } else if ('suspender' === this.acao) {
        this.suspender()
      } else if ('cancelar' === this.acao) {
        this.cancelar()
      }
    },

    ativar() {
      this.reservaCartaoService
        .ativarReservarCartao(this.idReservarCartao)
        .then(() => {
          this.loading = false
          this.$toast.add({
            severity: 'success',
            summary: 'Reserva de Cartão ativada com sucesso!',
            life: 10000,
          })
          this.carregarListaReservarCartao()
        })
        .catch((err) => {
          this.loading = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    suspender() {
      this.reservaCartaoService
        .suspenderReservarCartao(this.idReservarCartao)
        .then(() => {
          this.loading = false
          this.$toast.add({
            severity: 'success',
            summary: 'Reserva de Cartão suspensa com sucesso!',
            life: 10000,
          })
          this.carregarListaReservarCartao()
        })
        .catch((err) => {
          this.loading = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    cancelar() {
      this.loading = true
      this.reservaCartaoService
        .cancelarReservarCartao(this.idReservarCartao)
        .then(() => {
          this.loading = false
          this.$toast.add({
            severity: 'success',
            summary: 'Reserva de Cartão cancelada com sucesso!',
            life: 10000,
          })
          this.carregarListaReservarCartao()
        })
        .catch((err) => {
          this.loading = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    exibirHistorico(historico) {
      this.historico = historico
      this.modalHistorico = true
    },

    labelChip(codigo) {
      if (codigo === 1) {
        return 'Ativo'
      } else if (codigo === 2) {
        return 'Suspensa'
      } else if (codigo === 3) {
        return 'Cancelada'
      }
    },

    classChip(codigo) {
      if (codigo === 1) {
        return 'mr-2 mb-2 custom-chip ativa'
      } else if (codigo === 2) {
        return 'mr-2 mb-2 custom-chip suspensa'
      } else if (codigo === 3) {
        return 'mr-2 mb-2 custom-chip cancelada'
      }
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.cancelada {
  color: #fff;
  background-color: #dc3545;
  font-weight: bold;
  text-align: center;
}

.ativa {
  color: #fff;
  background-color: #28a745;
  font-weight: bold;
  text-align: center;
}

.suspensa {
  color: #1f2d3d;
  background-color: #c0c0c0;
  font-weight: bold;
  text-align: center;
}
</style>
